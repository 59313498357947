import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { isLoading } from '../stores/loading';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/visual-art',
    name: 'visual-art',
    component: () => import(/* webpackChunkName: "visual-art" */ '../views/VisualArtView.vue'),
  },
  {
    path: '/radio',
    name: 'radio',
    component: () => import(/* webpackChunkName: "radio" */ '../views/RadioView.vue'),
  },
  {
    path: '/shows',
    name: 'shows',
    component: () => import(/* webpackChunkName: "shows" */ '../views/ShowsView.vue'),
  },
  {
    path: '/artists',
    name: 'artists',
    component: () => import(/* webpackChunkName: "artists" */ '../views/ArtistsView.vue'),
  },
  // Añade más rutas según sea necesario
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Añadir un hook global después de cada cambio de ruta
router.afterEach((to) => {
  const canonicalLink = document.querySelector("link[rel='canonical']");
  const baseUrl = 'https://astra.fm'; // Tu dominio base

  if (canonicalLink) {
    // Si ya existe, actualiza el valor href
    canonicalLink.setAttribute('href', `${baseUrl}${to.path}`);
  } else {
    // Si no existe, crea la etiqueta canónica
    const newCanonicalLink = document.createElement('link');
    newCanonicalLink.setAttribute('rel', 'canonical');
    newCanonicalLink.setAttribute('href', `${baseUrl}${to.path}`);
    document.head.appendChild(newCanonicalLink);
  }
});

router.beforeEach((to, from, next) => {
  isLoading.value = true; // Activa el spinner de carga
  next();
});

router.afterEach(() => {
  isLoading.value = false; // Desactiva el spinner de carga
});

export default router;




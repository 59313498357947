<script setup>
import PlayerSite from './PlayerSite.vue';
import logo from '@/assets/logo.png'; // Asegúrate de que la ruta es correcta

import { ref } from 'vue';

const isMenuOpen = ref(false);

// Funciones separadas para abrir y cerrar el menú
function openMenu() {
  isMenuOpen.value = true;
}

function closeMenu() {
  isMenuOpen.value = false;
}
// Función para cerrar el menú en dispositivos móviles al hacer clic en un ítem de navegación
function closeMenuOnMobile() {
  // Consideramos dispositivos móviles aquellos con un ancho de pantalla menor a 768px
  if (window.innerWidth < 768) {
    closeMenu();
  }
}
</script>
<template>
    <div class="container-fluid">
        <div class="header">
            <div class="logo-nav">
                <div class="logo">
                <router-link to="/" title="Astra FM">
                   <h1><img :src="logo" alt="Astra logo"></h1>
                </router-link>
                </div>
                <div :class="{'menu-modal': true, 'active': isMenuOpen}">
                    <nav class="navigation">
                        <a class="close-icon" @click="closeMenu" title="Close Menu"><i class="fa-solid fa-xmark"></i></a>
                        <router-link to="/radio" exact-active-class="active-link" class="aa-nav-item" title="Radio" @click="closeMenuOnMobile">Radio</router-link> 
                        <router-link to="/visual-art" exact-active-class="active-link" class="aa-nav-item" title="Arte Visual" @click="closeMenuOnMobile">Arte Visual</router-link> 
                        <router-link to="/shows" exact-active-class="active-link" class="aa-nav-item" title="Shows" @click="closeMenuOnMobile">Shows</router-link> 
                        <router-link to="/artists" exact-active-class="active-link" class="aa-nav-item" title="Artistas" @click="closeMenuOnMobile">Artistas</router-link> 
                        <router-link to="/about" exact-active-class="active-link" class="aa-nav-item" title="Sobre Astra" @click="closeMenuOnMobile">Sobre Astra</router-link> 
                    </nav>
                </div>
                <a class="hamburguer-icon" @click="openMenu" title="Menu"><i class="fa-solid fa-bars"></i></a>
            </div>
            <div class="aa-player">
                <PlayerSite />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/astra-vars.scss";
.logo{
    img{
        max-width: 50px;
    }
}
.close-icon{
    position: absolute;
    right:20px;
    margin-bottom:100px;
}
.navigation .aa-nav-item {
  color: $font-color-primary;
  text-decoration: none;
  position: relative;
  margin-right: 20px; 
  font-size: 24px;
  text-align: center;
  margin-top:10px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    background-color:transparent;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
  
}

.hamburguer-icon, .close-icon {
  display: block; /* Visible en móviles */
  font-size: 24px;
  color:$font-color-primary;
  cursor: pointer;
  /* Añade más estilos según necesites */
}

/* Oculta la navegación en móviles */
.navigation {
  display: none;
  .aa-nav-item{
    display:block;
  }
  .active-link::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

/* La clase 'active' controlará la visibilidad del menú en dispositivos móviles */
.menu-modal.active .navigation {
  flex-direction: column; /* O cualquier dirección que se ajuste a tu diseño */
  position: absolute; /* O 'fixed', dependiendo de cómo quieras que se muestre la modal */
  top: 0; /* Ajusta según necesites */
  left: 0; /* Ajusta según necesites */
  width: 100%; /* Ajusta según necesites */
  height:100vh;
  background-color: rgb(14, 14, 14); /* Ajusta según necesites */
  display: block;
  z-index: 9999;
  /* Añade más estilos según necesites */
}

/* Estilos para pantallas más grandes */
@media (min-width: $layout-breakpoint-mediumxl) {
  
  .navigation .aa-nav-item {
    &::before {
      content: '';
      height: 2px;
      background-color: $primary-color;
    }

    &:hover::before {
      transform-origin: left;
      transform: scaleX(1);
    }
    
  }

  .hamburguer-icon {
    display: none; /* Oculta el ícono de hamburguesa en escritorio */
  }
  .close-icon{
    display: none;
  }
  .aa-nav-item{
    display:inline-block;
  }
  .navigation .aa-nav-item {
    font-size: inherit;
    text-align: left;
    padding-bottom:0;
    margin-top:inherit;
  }
  
  /* Muestra la navegación en escritorio */
  .navigation, .menu-modal.active .navigation {
    display: flex; /* Ajusta según tu diseño */
    /* Restablece cualquier estilo específico de la modal si es necesario */
  }

}


</style>

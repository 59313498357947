<script setup>
  import { ref, onMounted, onUnmounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import HeaderSite from './components/HeaderSite.vue'
  import FooterSite from './components/FooterSite.vue'
  import SpinnerSite from './components/SpinnerSite.vue';
  import { isLoading } from './stores/loading';
  

  const isHeaderVisible = ref(true);
let inactivityTimer = null;

// Utilizamos useRoute para detectar la ruta actual
const route = useRoute();

// Función para ocultar el header
function hideHeader() {
  // Solo actúa si estamos en la vista de Home
  if (route.path === '/') {
    isHeaderVisible.value = false;
  }
}

// Función para mostrar el header
function showHeader() {
  isHeaderVisible.value = true;
}

// Función para reiniciar el temporizador de inactividad
function resetInactivityTimer() {
  clearTimeout(inactivityTimer);
  showHeader(); // Muestra el header cuando hay actividad
  inactivityTimer = setTimeout(hideHeader, 5000); // Oculta el header después de 5 segundos de inactividad
}

// Observamos la ruta para resetear el estado del header cuando cambiamos de vista
watch(() => route.path, (newPath) => {
  if (newPath === '/') {
    resetInactivityTimer(); // Reinicia el temporizador si estamos en Home
  } else {
    clearTimeout(inactivityTimer); // Detiene el temporizador si no estamos en Home
    showHeader(); // Asegura que el header esté visible fuera de Home
  }
}, { immediate: true });

onMounted(() => {
  document.addEventListener('mousemove', resetInactivityTimer);
});

onUnmounted(() => {
  document.removeEventListener('mousemove', resetInactivityTimer);
});

</script>

<template>
  <transition name="slide" @after-enter="resetTimer" @after-leave="resetTimer">
    <!-- Usa una clase condicional para controlar la visibilidad -->
    <div :class="{ 'is-hidden': !isHeaderVisible }" class="header">
      <HeaderSite />
    </div>
  </transition>

  <!-- Transición para el spinner -->

    <SpinnerSite v-if="isLoading" />

  <!-- Transición aplicada al contenido del router-view -->
 
    <router-view :key="$route.fullPath" />

  <FooterSite />
</template>


<style>
.header {
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(0);
  opacity: 1;
}

.is-hidden {
  opacity: 0;  
  transform: translateY(-100%); /* O cualquier otro método para ocultarlo */
}

/* Estado inicial para entrar y estado final para salir */
.slide-enter-from, .slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Estado activo para transiciones de entrada y salida */
.slide-enter-active, .slide-leave-active {
  position: fixed;
  width: 100%;
}
</style>


import { createApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head'
import router from './router'
import { createPinia } from 'pinia';
import VueLazyload from 'vue-lazyload';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/astra-styles.scss';
import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp(App);
const head = createHead();
app.use(VueLazyload);
app.use(head);
app.use(createPinia());
app.use(router);
app.mount('#app');

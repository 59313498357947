export function getCurrentShow() {
    const now = new Date();
    const hour = now.getHours();
  
    const shows = window.myData.shows[0]; // Asume que siempre estamos interesados en el primer "día"
    const subtitles = window.myData.subtitle[0];
    const images = window.myData.images[0];
    const descriptions = window.myData.descriptions[0];
  
    let currentShow = null;
    for (const [startHour, showInfo] of Object.entries(shows)) {
        const [start, end] = showInfo.range;
        if (hour >= start && hour < end) {
            currentShow = {
                ...showInfo,
                subtitle: subtitles[startHour]?.name,
                image: images[startHour]?.image,
                description: descriptions[startHour]?.description,
                startTime: start, // Añade la hora de inicio del show
                endTime: end, // Añade la hora de fin del show
            };
            break;
        }
    }
  
    return currentShow;
}


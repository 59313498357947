// src/store/loadingState.js
import { ref } from 'vue';

export const isLoading = ref(false);

// En tu store o componente
const isTransitioning = ref(false);

// Antes de cambiar de ruta
isTransitioning.value = true;

// Después de que la ruta ha cargado completamente
isTransitioning.value = false;

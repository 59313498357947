<script setup>
import { onMounted, ref, nextTick } from 'vue';
import { fetchPostsByCategorySlug } from '@/services/api';
import SpinnerSite from '../components/SpinnerSite.vue';
import { isLoading } from '@/stores/loading'; // Asegúrate de que la ruta sea correcta
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";
import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";


const posts = ref([]);

// Función para mezclar los elementos de un array
function shuffleArray(array) {
  let currentIndex = array.length, randomIndex;

  // Mientras queden elementos a mezclar...
  while (currentIndex != 0) {
    // Selecciona un elemento sin mezclar...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Y lo intercambia con el elemento actual.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

// Función para precargar imágenes
async function preloadImages(posts) {
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  const imagePromises = posts.map(post => loadImage(post.acf.picture));
  await Promise.all(imagePromises);
}

onMounted(async () => {
  try {
    const categoryId = '8';
    const perPage = '58';
    const data = await fetchPostsByCategorySlug(categoryId, perPage);
    const shuffledData = shuffleArray(data); // Mezcla los posts antes de asignarlos
    posts.value = shuffledData;

    // Precarga las imágenes antes de inicializar el carrusel
    await preloadImages(shuffledData);

    await nextTick();

    const container = document.getElementById("myCarousel");
    if (container) {
      const options = {
        Autoplay: {
          timeout: 10000,
          pauseOnHover: false,
        },
        transition: 'fade',  // Cambiar a fade para una transición suave
        duration: 600, // Duración de la transición en milisegundos
        easing: 'ease-in-out' // Asegura una transición suave
      };
      new Carousel(container, options, { Autoplay });
  
    }
  } catch (error) {
    console.error('Error fetching posts:', error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
    <div class="f-carousel" id="myCarousel">
      <!-- Iteramos sobre los posts con v-for -->
          <template v-for="post in posts" :key="post.id">
            <div class="f-carousel__slide">
              <!-- Asumiendo que 'acf' es un objeto en tus posts, si no es así ajusta las propiedades -->
              
              <!-- Usamos :src para enlazar la dirección de la imagen -->
             <img :src="post.acf.picture" />

              <div class="picture-info">
                <p>{{ post.acf.name }}</p>
                <p>{{ post.acf.location }}</p>
              </div>
            </div>
          </template>
          <div class="f-carousel__nav">
            <button tabindex="0" title="Next slide" class="f-button is-next" data-carousel-next="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M9 3l9 9-9 9"></path></svg>
            </button>
            <button tabindex="0" title="Previous slide" class="f-button is-prev" data-carousel-prev="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M15 3l-9 9 9 9"></path></svg>
            </button>
          </div>
      </div>
      <div v-if="posts.length === 0">
        <SpinnerSite />
      </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/astra-vars.scss";

.container, .row, .f-carousel {
  height: 100vh; // Ocupa todo el alto de la pantalla
  width: 100vw; // Ocupa todo el ancho de la pantalla
  margin: 0; // Elimina márgenes por defecto
  padding: 0; // Elimina paddings por defecto
  overflow: hidden; // Oculta cualquier contenido que se desborde
}

#myCarousel, #myCarousel * {
    cursor: grab;
  }
@media (min-width: $layout-breakpoint-mediumxl) { 
  #myCarousel, #myCarousel * {
    cursor: default;
  }
}


.f-button{
  visibility: hidden!important;
}
.f-carousel__slide {
  height: 100%; // Ocupa el 100% de la altura del contenedor padre
  width: 100%; // Ocupa el 100% de la anchura del contenedor padre
  display: flex;
  position: relative;
  justify-content: center; // Centra el contenido horizontalmente
  align-items: center; // Centra el contenido verticalmente
  overflow: hidden; // Oculta cualquier contenido que se desborde

  img {
    width: 100%; // Asegura que la imagen no exceda el ancho del contenedor
    height: 100%; // Asegura que la imagen no exceda la altura del contenedor
    object-fit: cover; // Cubre el área del contenedor, cortando el exceso de imagen
  }
  .picture-info{
    position: absolute;
    padding:$s12;
    border-radius: $s08;
    background-color: rgba(57, 57, 57, 0.45);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    text-align: center;
    bottom: 10%;
    color: $font-color-primary;
    p{
      margin:0;
    }
  }
  
}

</style>


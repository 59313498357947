<!-- Spinner.vue -->
<template>
    <div class="spinner-overlay">
      <span class="loader"></span>
    </div>
</template>
  
<style scoped lang="scss">
@import "@/assets/scss/astra-vars.scss";
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:$bg-color-primary;
    z-index: 9999;
}

.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}
.loader::after, .loader::before {
    content: '';  
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #FFF;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}
.loader::after {
    animation-delay: 1s;
}

@keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
</style>
  